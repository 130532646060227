

.AlmProfileCode {
    border: .5px solid grey;
    width:150px;
    height: 100%;
}

.AlmProfileName {
    border: .5px solid grey;
    width:300px;
    height: 100%;
}

.AlmProfileCritical {
    background-color: #E59D88;
    border: .5px solid grey;
    width:80px;
    height: 100%;
    text-align:center;
}

.AlmProfileMajor {
    background-color: #F4CF95;
    border: .5px solid grey;
    width:80px;
    height: 100%;
    text-align:center;
}

.AlmProfileMinor {
    background-color: #FCF2B8;
    border: .5px solid grey;
    width:80px;
    height: 100%;
    text-align:center;
}

.AlmProfileNormal {
    background-color: white;
    border: .5px solid grey;
    width:80px;
    height: 100%;
    text-align:center;
}