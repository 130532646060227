

.selectedRow {
    font-weight: bold;
    background-color: bisque;
}

.activeVicu {
    font-weight: bold;
    background-color: bisque;

}