.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
.links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    justify-content: space-evenly;
}
.user {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo:hover {
    cursor: pointer;
}
